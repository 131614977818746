import { gsap } from 'gsap'
import { ScrollTrigger, CustomEase } from 'gsap/all'

gsap.registerPlugin(ScrollTrigger, CustomEase)
CustomEase.create('easeInOutCirc', '.85, 0, .15, 1')

export function basic() {
  document.body.style.overflow = 'auto'
}

export function fadeIn() {
  const markerState = false
  const fadeIns = [...document.querySelectorAll('.fade-in-item')]
  const fadeInStaggers = [...document.querySelectorAll('.fade-in-stagger')]

	if(fadeIns.length > 0) {
		fadeIns.forEach(item => {
			ScrollTrigger.create({
				animation: gsap.to(item, {
					opacity: 1,
					y: 0,
					duration: 1,
					ease: 'expo.out',
					pointerEvents: 'auto'
				}),
				start: 'top 75%',
				trigger: item,
				markers: markerState
			})
		})
	}

	if(fadeInStaggers.length > 0) {
    fadeInStaggers.forEach(item => {
      const children = [...item.querySelectorAll('.stagger-item')]
      ScrollTrigger.create({
        animation: gsap.to(children, {
          opacity: 1,
          y: 0,
					stagger: .1,
          duration: 1,
          ease: 'expo.out',
					pointerEvents: 'auto'
        }),
        start: 'top 75%',
        trigger: item,
        markers: markerState
      })
    })
	}
}

export function homeLabs() {
  const $ = gsap.utils.selector('#home-labs')
	const tl = gsap.timeline()
	let optionalTranslateDuration = .75

	if(window.innerWidth < 1024) optionalTranslateDuration = 0

	tl
		.to($('[data-part="center"]'), {
			scale: 1,
			duration: 1,
			ease: 'elastic.out(1,1)'
		})
		.to($('[data-part="back"]'), {
			scale: 1,
			duration: 1,
			ease: 'elastic.out(1,1)'
		}, 0.25)
		.to($('.light-wrapper'), {
			x: '255%',
			duration: 1,
			ease: 'expo.out'
		}, '-=.5')
		.to($('.labs-illustration'), {
			x: 0,
			duration: optionalTranslateDuration,
			ease: 'expo.out'
		}, '-=.45')
		.to($('.content-item'), {
			y: 0,
			opacity: 1,
			stagger: .1,
			ease: 'power2.out'
		}, '<')

	ScrollTrigger.create({
		animation: tl,
		start: 'top 50%',
		trigger: $('.labs-illustration'),
		// markers: true
	})
}