import gsap from 'gsap'
import zoetis from './../lottie/zoetis.json';
import * as interactions from './interactions'
import * as animations from './animations'
import * as setup from './setup'
const lottie = require('lottie-web')

const zoetisAnimation = lottie.loadAnimation({
  container: document.querySelector('.loader-anim'),
  renderer: 'svg',
  loop: false,
  autoplay: false,
  animationData: zoetis
})

class App {
  constructor() {
    this.setup()
    this.scroll()

    interactions.header()
    interactions.menu()
    interactions.homeSlider()
    interactions.homeHero()
  }

  setup() {
    setup.basic()
    setup.fadeIn()
    setup.homeLabs()
  }

  load() {
    animations.basic()
    animations.fadeIn()
    animations.homeLabs()
  }

  scroll() {
    window.addEventListener('scroll', () => {
      interactions.header()
    })
  }
}

const app = new App()

document.addEventListener('DOMContentLoaded', () => {
  zoetisAnimation.addEventListener('DOMLoaded', () => {
    zoetisAnimation.play()
    const tl = gsap.timeline({
      // delay: 2.5,
      delay: 0,
      onComplete: () => {
        gsap.set('#loader', {
          display: 'none'
        })
      }
    })
    tl
      .set('#loader', {
        transformOrigin: 'top'
      })
      .to('.loader-anim', {
        opacity: 0,
        // scale: 1.5,
        scale: 1.1,
        duration: .3,
        onComplete: () => {
          console.log('load fade in animation here')
          app.load()
        }
      })
      .to('#loader', {
        scaleY: 0,
        ease: 'power4.out'
      }, .3)
  })
})