import { gsap } from 'gsap'

export function basic() {
  document.body.style.overflow = 'hidden'
}

export function fadeIn() {
  const fadeIns = [...document.querySelectorAll('.fade-in-item')]
  const fadeInStaggers = [...document.querySelectorAll('.fade-in-stagger')]
  const gap = 50
  const opacity = 0

  if(fadeIns.length > 0) {
    fadeIns.forEach(item => {
      gsap.set(item, {
        opacity: opacity,
        y: gap,
        pointerEvents: 'none'
      })
    })
  }

  if(fadeInStaggers.length > 0) {
    fadeInStaggers.forEach(item => {
      const children = [...item.querySelectorAll('.stagger-item')]
      if(children.length > 0) {
        children.forEach(child => {
          gsap.set(child, {
            opacity: opacity,
            y: gap,
            pointerEvents: 'none'
          })
        })
      }
    })
  }
}

export function homeLabs() {
  const $ = gsap.utils.selector('#home-labs')
  const tl = gsap.timeline()
  tl
    .set($('.labs-illustration'), {
      x: () => window.innerWidth < 1024 ? '0%' : '-50%'
    })
    .set([$('[data-part="center"]'), $('[data-part="back"]')], {
      scale: 0,
      transformOrigin: 'center'
    })
    .set($('.content-item'), {
      y: 30,
      opacity: 0
    })
}