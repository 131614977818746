import { play } from 'bodymovin'
import { gsap } from 'gsap'
import Plyr from 'plyr'
const Flickity = require('flickity')

// header
export function header() {
	const y = window.scrollY
  const mainHeader = document.getElementById('main-header')
  const topHeader = document.getElementById('top-header')
  if(y > topHeader.clientHeight) {
    mainHeader.classList.add('shadow-md')
  } else {
    mainHeader.classList.remove('shadow-md')
  }
}

// menu
export function menu() {
  let headerState = false
  const header = document.getElementById('main-header')
  const burgerMenu = document.querySelector('.burger-menu')
  const headerNavMobile = document.querySelector('.header-nav-mobile')
  const headerNavMobileItems = [...headerNavMobile.querySelectorAll('.inner > ul > li')]
  const submenuTriggers = [...headerNavMobile.querySelectorAll('.submenu-trigger')]
  const backTriggers = [...headerNavMobile.querySelectorAll('.back-button')]

  // burger menu
  burgerMenu.addEventListener('click', function() {
    console.log(headerNavMobileItems)
    headerState = !headerState
    if(headerState) {
      header.classList.add('show-mobile-nav')
      gsap.to(headerNavMobile, {
        height: '100dvh',
        ease: 'power4.out'
      })
      gsap.to(headerNavMobileItems, {
        y: 0,
        opacity: 1,
        stagger: .075,
        ease: 'power2.out'
      })
    } else {
      header.classList.remove('show-mobile-nav')
      gsap.to(headerNavMobile, {
        height: 70,
        ease: 'power4.out',
        onComplete: () => {
          gsap.set(headerNavMobileItems, {
            clearProps: 'all'
          })
        }
      })
    }
  })

  // submenu triggers
  submenuTriggers.forEach(trigger => {
    trigger.addEventListener('click', function() {
      const target = this.dataset.target
      header.classList.add('show-' + target)
    })
  })

  // back button triggers
  backTriggers.forEach(trigger => {
    const parent = trigger.parentElement
    const target = parent.id
    trigger.addEventListener('click', function() {
      header.classList.remove('show-' + target)
    })
  })
}

// home slider
export function homeSlider() {
  const wrapper = document.querySelector('.testimonial-carousel')
  if(wrapper) {
    var flkty = new Flickity(wrapper, {
      // options
      cellAlign: 'left',
      contain: true,
      arrowShape: 'M89.571 43.714H27.857l14.85-14.85c1.267-1.266 1.864-2.777 1.864-4.435 0-3.163-2.613-6.429-6.428-6.429-1.707 0-3.195.62-4.436 1.864l-25.58 25.58C7.075 46.498 6 47.8 6 50.143s.897 3.471 2.076 4.65l25.631 25.628c1.24 1.244 2.73 1.865 4.436 1.865 3.818 0 6.428-3.266 6.428-6.429 0-1.658-.597-3.17-1.864-4.436l-14.85-14.85h61.714a6.429 6.429 0 0 0 0-12.857Z'
    });
  }
}

// video
export function homeHero() {
  const wrapper = document.querySelector('#home-hero')
  if(wrapper) { 
    const video = wrapper.querySelector('.video-player')
    const popup = wrapper.querySelector('.video-popup')
    const trigger = wrapper.querySelector('.video-popup-trigger')
    const closer = wrapper.querySelector('.video-popup-close-trigger')
    const player = new Plyr(video)

    trigger.addEventListener('click', () => {
      document.body.style.overflow = 'hidden'
      popup.classList.add('is-active')
      player.play()
    })

    closer.addEventListener('click', () => {
      document.body.style.overflow = 'auto'
      popup.classList.remove('is-active')
      player.stop()
    })
  }
}